<!-- 用户换电统计 -->
<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select v-model="query.sendId" placeholder="请选择站点" clearable filterable>
        
          <el-option
            v-for="(item, index) in stationList.list"
            :key="index"
            :label="item.stationName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="initData()">查询</el-button>
      </el-col>
    </el-row>
    <view>
      共 {{count}} 次换电
    </view>
    <el-table
      height="650"
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="时间" prop="Xlabel"></el-table-column>
      <el-table-column label="总换电次数" prop="iTotal"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { exchangeRecordDay } from "@/api/statistics";
import moment from "moment";
import { mapState } from "vuex";

export default {
  computed: mapState({
    stationList(state) {
      return state.station.stationList || { map: {}, list: [] };
    },
  }),

  data() {
    let startTime = moment().subtract(1, "d").startOf("d").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().startOf("d").format("YYYY-MM-DD HH:mm:ss");
    
    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      count: 0,
      query: {
        startTime: startTime,
        endTime: endTime,
      },
    };
  },

  mounted() {
    this.initData();
    this.$store.dispatch("fetchStationAll");
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    initData() {
      exchangeRecordDay(this.query).then((res) => {
        this.dataSource = res.data.data.records || [];
        let count = 0;
        this.dataSource.map((item) => {
          count += item.iTotal;
        });

        this.count = count;
      });
    },
  },
};
</script>
